export interface Traveller {
  lacNo: number;
  tacNo: number | null;
  tatooNumber: number; //more descriptive name needed?
  requirements: RequirementDetails[];
  fullName: string;
  agreementCodes: Fqv[];
  tickets: Ticket[];
  energyMarineInfo: ERMInfo | null;
  cnfs: Cnf[];
}

export interface Cnf {
  CnfCode: string;
  Carriers: string[];
  Products: string[];
}

export interface Fqv {
  VendorCode: string;
  Type: string;
  Code: string;
}

export enum SegmentType {
  PT = "PT",
  ST = "ST",
  OT = "OT",
}

export interface RequirementDetails {
  name: RequirementName;
  value: string | undefined;
  isValid: boolean | undefined;
  errorMessage: string | undefined;
}

export interface TravelPass {
  code: string;
  usesLeft: number;
}

export interface Ticket {
  fareType: string;
  currency: string;
  amount: number;
  ticketType: "E-Ticket" | "TPC" | "TravelPass";
  travelpass: TravelPass | null;
  isAssociatedWithMarine: boolean;
}

export enum RequirementName {
  CustRef = "CustRef",
  ProCode = "ProCode",
  ProSubCode = "ProSubCode",
  DepCode = "DepCode",
  TravCode = "TravCode",
}

export interface ERMInfo {
  regCountryCode: string | undefined;
  ermName: string | undefined;
  marineCode: MarineCode;
}

export enum MarineCode {
  VSL = "VSL",
  RIG = "RIG",
  WMF = "WMF",
}
