interface Status {
  Code: string;
  Message: string;
}

interface Employee {
  EmpCode: string;
  FirstName: string;
  LastName: string;
  FullName: string;
  PhoneNumbers: string[];
  EmailAddresses: string[];
  FirmCode: string;
  BureauCode: string;
}

interface Email {
  Address: string;
  Info: boolean;
  Itinerary: boolean;
  Receipt: boolean;
  ETicketReceipt: boolean;
  Invoice: boolean;
}

interface FqvDto {
  VendorCode: string;
  Type: string;
  Code: string;
}

interface CustRef {
  Label: string;
  Description: string;
  Dynamic: boolean;
  Type: string;
}

interface TravCode {
  Label: string;
  Description: string;
  Dynamic: boolean;
  Type: string;
}

interface DropDownItem {
  Value: string;
  Text: string;
}

interface ProCode {
  Label: string;
  Description: string;
  Dynamic: boolean;
  Type: string;
  DropDownList: DropDownItem[];
}

interface ProSubCode {
  Label: string;
  Description: string;
  Dynamic: boolean;
  Type: string;
}

interface CreditCard {
  Code: string;
  NoEncode: string;
  Display: string;
  ExpYear: number;
  ExpMonth: number;
  UseBusiness: boolean;
  UsePrivate: boolean;
  UseHotelGuarantee: boolean;
  IsLodgeCard: boolean;
  FreeText: string;
}

export interface CnfDto {
  CnfCode: string;
  Carriers: string[];
  Products: string[];
}

export interface Lac {
  [key: string]: any;
  LacNo: number;
  LacName: string;
  LacNameSearch: string;
  FirmCode: string;
  Active: boolean;
  RacNo: number;
  NacNo: number;
  GacNo: number;
  CustomerLevel: string;
  CurrencyCode: string;
  LanguageCode: string;
  FormOfPayment: string;
  UseOfficeID: string;
  UseTac: boolean;
  UseProfile: boolean;
  UseAB: boolean;
  UseAM: boolean;
  PolicyCode: string;
  ConfigCode: string;
  MatrixServiceNo: number;
  IsCwtCustomer: boolean;
  HasMissedSavings: boolean;
  Employee: Employee;
  Phones: any[]; // Assuming this is an empty array or could be an array of some object type
  Emails: Email[];
  Fqvs: FqvDto[];
  CustRef: CustRef | undefined;
  TravCode: TravCode | undefined;
  ProCode: ProCode | undefined;
  ProSubCode: ProSubCode | undefined;
  DepCode: object | undefined;
  CreditCards: CreditCard[];
  Cnfs: CnfDto[]; //avtalekoder fly - tpc eticket alle sendes inn til amadeus
  Profiles: Profile[];
  Savings: any[]; // Assuming this is an empty array or could be an array of some object type
  Ticketing: Ticketing;
  Refund: Refund;
  CarVoucher: boolean;
  HotelSIs: HotelSI[];
}

interface Profile {
  GdsCode: string;
  RecLoc: string;
  Name: string;
  OfficeID: string;
}

interface Ticketing {
  Offline: string;
  Online: string;
}

interface Refund {
  OfficeID: string;
  QueueNo: number;
  QueueCatNo: number;
}

interface HotelSI {
  SI: string;
  Countries: string[];
  Chains: string[];
  Hotels: string[];
}


export interface GetLacResponse {
  Status: Status;
  Lac: Lac;
}

export enum StatusCode {
  Success = "Success",
  Failure = "Failure",
}
