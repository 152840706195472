import { Box } from "@mui/material";
import { PNR } from "websdk-businessobjects";

import Collapsible from "./Collapsible";
import RequirementsForm from "./RequirementsForm";
import { Traveller } from "../../traveller";

interface RequirementsProps {
  pnr: PNR;
  fetchData: () => Promise<void>;
  travellers: Traveller[];
}

const Requirements = ({ pnr, fetchData, travellers }: RequirementsProps) => {
  return (
    <Box display="flex" flexDirection="column" alignContent="start">
      <Collapsible title="Requirements" isDefaultExpanded={false}>
        {pnr &&
          travellers.map((traveller) => (
            <RequirementsForm
              key={traveller.tatooNumber}
              traveller={traveller}
              fetchData={fetchData}
              pnr={pnr}
            />
          ))}
      </Collapsible>
    </Box>
  );
};

export default Requirements;
