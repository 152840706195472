import {
  AddCancelInput,
  AddRemarkInput,
  NameElement,
  PNR,
  RMElement,
  TST,
} from "websdk-businessobjects";

import { fetchLac, validateRequirements } from "./services";
import {
  ERMInfo,
  MarineCode,
  RequirementDetails,
  RequirementName,
  SegmentType,
  Ticket,
  Traveller,
} from "../../traveller";
import { Lac, StatusCode } from "../../lacDto";
import { RmFullNode } from "./dtos/rmFullNodeDto";
import { extractTacNumber } from "../Pricing/utils";

export const findRmElement = (
  pnr: PNR,
  key: RequirementName,
  travellerTatooNumber: number
) => {
  return pnr?.rmElements.find(
    (rm) =>
      rm.freeFlowText.startsWith(toRequirementCode(key)) &&
      rm.category === "*" &&
      rm.associations.some(
        (a) => a.tatooNumber === travellerTatooNumber.toString()
      )
  );
};

export const toRequirement = (
  pnr: PNR,
  key: RequirementName,
  travellerTatooNumber: number
): RequirementDetails => {
  const newLocal = findRmElement(pnr, key, travellerTatooNumber)!;
  return {
    value: newLocal?.freeFlowText.split("+")[2] ?? "",
    name: key,
    isValid: false,
    errorMessage: undefined,
  };
};

enum MarineFields {
  REG = "REG",
  RIG = "RIG",
  WMF = "WMF",
  VSL = "VSL",
  DIR = "DIR",
  COMP = "COMP",
  PHONE = "PHONE",
}

export const toRequirementCode = (requirement: RequirementName): string => {
  switch (requirement) {
    case RequirementName.CustRef:
      return "BH+R+";
    case RequirementName.ProCode:
      return "XI+PRO+";
    case RequirementName.ProSubCode:
      return "XI+SUB+";
    case RequirementName.DepCode:
      return "XI+DNO+";
    case RequirementName.TravCode:
      return "XI+ENO+";
    default:
      throw new Error("Invalid requirement");
  }
};

export const toRequirements = (
  lac: Partial<Lac>,
  pnr: PNR,
  travallerTatooNumber: number
): RequirementDetails[] => {
  return Object.keys(RequirementName)
    .filter((key) => lac[key] !== undefined)
    .map((key) =>
      toRequirement(pnr, key as RequirementName, travallerTatooNumber)
    );
};

export const toLacNoNameElement = (pnr: PNR, nameElement: NameElement) => {
  const key = "*ACC";
  const lacKey = pnr.rmElements.find((rm) => {
    if (!rm.fullNode) return false;
    return (
      (
        rm.fullNode as RmFullNode
      ).miscellaneousRemarks?.remarks?.freetext?.startsWith(key) &&
      (rm?.associations?.some(
        (e) => e.tatooNumber === nameElement.tatooNumber
      ) ??
        true)
    );
  })!;

  const freeFlowText = (lacKey.fullNode as RmFullNode).miscellaneousRemarks
    ?.remarks?.freetext;
  return freeFlowText.slice(4, freeFlowText.length);
};

export const initTravellers = async (pnr: PNR, tst: TST) => {
  const promises = pnr.nameElements.map(async (nameElement) => {
    const lacNo = parseInt(toLacNoNameElement(pnr, nameElement));
    const lac = await fetchLac(lacNo);
    if (lac.Status.Code === StatusCode.Failure)
      throw new Error("Failed to fetch LAC");
    const requirements = toRequirements(
      lac.Lac,
      pnr,
      parseInt(nameElement.tatooNumber)
    );
    const vr = await validateRequirements(lacNo, requirements);

    const ermInfo = extractErmInfo(pnr, parseInt(nameElement.tatooNumber));

    const tickets: Ticket[] = extractTickets(tst, nameElement, !!ermInfo);

    const tacNo = extractTacNumber(pnr, parseInt(nameElement.tatooNumber));

    const traveller: Traveller = {
      lacNo: lacNo,
      tacNo: tacNo,
      tatooNumber: parseInt(nameElement.tatooNumber),
      requirements: vr,
      fullName: nameElement.firstName + " " + nameElement.lastName,
      agreementCodes: lac?.Lac?.Fqvs ?? [],
      tickets: tickets,
      energyMarineInfo: ermInfo,
      cnfs: lac.Lac.Cnfs.map((cnf) => {
        return {
          Carriers: cnf.Carriers,
          CnfCode: cnf.CnfCode,
          Products: cnf.Products,
        };
      }),
    };
    return traveller;
  });

  return await Promise.all(promises);
};

const extractMarineCode = (marineRm: RMElement) => {
  if (marineRm.freeFlowText.startsWith(MarineCode.RIG.toString()))
    return MarineCode.RIG;
  if (marineRm.freeFlowText.startsWith(MarineCode.VSL.toString()))
    return MarineCode.VSL;
  if (marineRm.freeFlowText.startsWith(MarineCode.WMF.toString()))
    return MarineCode.WMF;
  throw new Error("Invalid marine code");
};

const extractTravelPass = (fe: any) => {
  return {
    code: "code",
    usesLeft: 100,
  };
};

const extractTickets = (
  tst: TST,
  nameElement: NameElement,
  isAssociatedWithMarine: boolean
): Ticket[] => {
  return tst.list
    .filter(
      (te) =>
        te.associations.some(
          (a) =>
            a.tatooNumber === nameElement.tatooNumber &&
            a.segmentType === nameElement.segmentType
        ) || !!te.associations
    )
    .flatMap((te) => {
      return te.grandTotalFareElements.map((fe) => {
        const ticket: Ticket = {
          fareType: fe.fareType,
          currency: fe.currency,
          amount: parseFloat(fe.amount),
          ticketType: "E-Ticket", //todo: implement a check to see if it is a TPC, Eticket or TravelPass
          travelpass: extractTravelPass(fe),
          isAssociatedWithMarine: isAssociatedWithMarine,
        };
        return ticket;
      });
    });
};

export const extractErmInfo = (pnr: PNR, travellerTatooNumber: number) => {
  const marineRm = findMarineRm(pnr, travellerTatooNumber);

  if (!marineRm) return null;

  const marineRmSplit = marineRm.freeFlowText.split("/");

  const marineInfo: MarineInfo = marineRmSplit.reduce((acc, curr) => {
    const [key, value] = curr.split(":");
    return { ...acc, [key as keyof MarineInfo]: value };
  }, {}) as MarineInfo;

  const ermInfo: ERMInfo = {
    regCountryCode: marineInfo["REG"] ?? "NO",
    ermName:
      (marineRm.freeFlowText.startsWith(MarineCode.VSL.toString()) &&
        marineInfo[MarineFields.VSL.toString()]) ||
      (marineRm.freeFlowText.startsWith(MarineCode.RIG.toString()) &&
        marineInfo[MarineFields.RIG.toString()]) ||
      (marineRm.freeFlowText.startsWith(MarineCode.WMF.toString()) &&
        marineInfo[MarineFields.WMF.toString()]) ||
      "",
    marineCode: extractMarineCode(marineRm),
  };

  return ermInfo;
};

interface MarineInfo {
  //todo: what can be undefined here?
  [key: string]: string | undefined;
  REG: string | undefined;
  RIG: string | undefined;
  WMF: string | undefined;
  VSL: string | undefined;
  DIR: string | undefined;
  COMP: string | undefined;
  PHONE: string | undefined;
}

export const findMarineRm = (pnr: PNR, travellerTatooNumber: number) => {
  const marineRm = pnr.rmElements.find((rm) => {
    const isMarineRm =
      rm.freeFlowText.startsWith(MarineCode.RIG.toString()) ||
      rm.freeFlowText.startsWith(MarineCode.VSL.toString()) ||
      rm.freeFlowText.startsWith(MarineCode.WMF.toString());

    const isAssociatedWithTraveller =
      !rm.associations ||
      rm.associations.length === 0 ||
      rm.associations.some(
        (a) => a.tatooNumber === travellerTatooNumber.toString()
      );

    return isMarineRm && isAssociatedWithTraveller;
  });

  return marineRm;
};

export const toMarineRemark = (
  ermInfo: ERMInfo,
  travellerTatoooNumber: number
) => {
  const marineCode = ermInfo.marineCode;
  const freeFlowText = `${marineCode.toString()}:${ermInfo.ermName}/${
    MarineFields.REG
  }:${ermInfo.regCountryCode}`;
  const addRemarkInput = {
    associations: [
      { segmentType: "PT", tatooNumber: travellerTatoooNumber.toString() },
    ],
    category: "*",
    freeFlowText,
  };
  return addRemarkInput;
};

export const existingRequirementRemarks = (
  pnr: PNR,
  travellerNo: number
): AddCancelInput[] => {
  const requirementCodes = [
    RequirementName.CustRef,
    RequirementName.DepCode,
    RequirementName.ProCode,
    RequirementName.ProSubCode,
    RequirementName.TravCode,
  ].map(toRequirementCode);

  return pnr.rmElements
    .filter((rmElement) =>
      requirementCodes.some(
        (code) =>
          rmElement.freeFlowText.startsWith(code) &&
          rmElement.associations.some(
            (a) => a.tatooNumber === travellerNo.toString()
          )
      )
    )
    .map(({ segmentType, tatooNumber }) => ({ segmentType, tatooNumber }));
};

export const requirementRemarks = (traveller: Traveller) => {
  const remarks: AddRemarkInput[] = traveller.requirements.map((r) => ({
    freeFlowText: toRequirementCode(r.name) + r.value,
    category: "*",
    remarkType: "RM",
    associations: [
      {
        segmentType: SegmentType.PT,
        tatooNumber: traveller.tatooNumber.toString(),
      },
    ],
  }));
  return remarks;
};
