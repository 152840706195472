import { session } from "websdk-core";
import { PricePNRResponse } from "./dtos/pricePNRWithLowerFaresResponse";
import { firstValueFrom } from "rxjs";
import { Fare, FareGroup } from "./models";
import { Cnf, Traveller } from "../../traveller";
import {
  requestServiceNames,
  toCreateFareRequest,
  toFareGroups,
  toPricePnrWithLowerFareRequest,
  toUpsellPnrRequest,
} from "./utils";

export const pricePNRWithLowerFare = async (cnfs: Cnf[]) => {
  const request = toPricePnrWithLowerFareRequest(cnfs);
  const response = await firstValueFrom(
    session.requestService(
      requestServiceNames.pricePNRWithLowerFare.toString(), //todo: remove tostring()
      request
    )
  );
  console.log(response);
  return response as PricePNRResponse;
};

export const priceUpsellPNR = async (cnfs: Cnf[]) => {
  const request = toUpsellPnrRequest(cnfs);
  console.log(request);
  const response = await firstValueFrom(
    session.requestService(
      requestServiceNames.priceUpsellPNR.toString(),
      request
    )
  );
  console.log(response);
  return response as PricePNRResponse;
};

export const fetchPrices = async (
  travellers: Traveller[]
): Promise<FareGroup[]> => {
  const cnfs = travellers.flatMap((traveller) => traveller.cnfs);

  const priceUpsell = await priceUpsellPNR(cnfs);

  if (
    !priceUpsell.response.model.fareList ||
    priceUpsell.response.model.fareList.length === 0
  ) {
    const priceLowerFare = await pricePNRWithLowerFare(cnfs);
    return toFareGroups(priceLowerFare) ?? [];
  }

  return toFareGroups(priceUpsell) ?? [];
};

export const createTSTfromPricing = async (fare: Fare) => {
  const request = toCreateFareRequest(fare);
  console.log(request);
  const createTstResponse = await firstValueFrom(
    session.requestService(
      requestServiceNames.createTSTfromPricing.toString(),
      request
    )
  );
  return createTstResponse;
};

export const fetchTacs = async (tacs: number[]) => {
  const url = process.env.REACT_APP_M3_URL + "tac?" + "TacNo={tac}&Opt=Fqv.SR";
  const promises = tacs.map((tac) =>
    fetch(url.replace("{tac}", tac.toString()))
  );
  return await Promise.all(promises);
};

export const allTacsHaveTpcCard = async (pnr: Traveller[]) => {
  if (pnr.find((traveller) => !traveller.tacNo)) return false;
  const tacs = await fetchTacs(pnr.map((traveller) => traveller.tacNo!));
  return tacs.every((tac) => tac.ok);
};
