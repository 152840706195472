import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { createTSTfromPricing } from "./services";
import { Fare } from "./models";

interface FareTableProps {
  fares: Fare[];
}

const FareTable: React.FC<FareTableProps> = ({ fares }) => (
  <TableContainer component={Paper}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Fare Family</TableCell>
          <TableCell>Fare Basis</TableCell>
          <TableCell>Luggage</TableCell>
          <TableCell>Amount</TableCell>
          <TableCell>Currency</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {fares.map((f) => (
          <TableRow
            onClick={() => {
              return createTSTfromPricing(f).then((response) => {
                console.log(response);
              });
            }}
            key={`${f.fareFamily}-${f.fareBasis}${f.amount}`}
            hover={true}
            sx={{ cursor: "pointer" }}
          >
            <TableCell>{f.fareFamily}</TableCell>
            <TableCell>{f.fareBasis}</TableCell>
            <TableCell>{JSON.stringify(f.luggage)}</TableCell>
            <TableCell>{f.amount}</TableCell>
            <TableCell>{f.currency}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default FareTable;
