import { PNR, PNRService } from "websdk-businessobjects";
import { ERMInfo } from "../../traveller";
import { findMarineRm, toMarineRemark } from "../requirements/utils";
import { firstValueFrom } from "rxjs";

export const createOrUpdateMarineRm = async (
  pnr: PNR,
  travellerTatoooNumber: number,
  ermInfo: ERMInfo
): Promise<PNR> => {
  const existingMarineRemark = findMarineRm(pnr, travellerTatoooNumber);
  if (existingMarineRemark) {
    pnr.addCancel({
      segmentType: existingMarineRemark.segmentType ?? "PT",
      tatooNumber: existingMarineRemark.tatooNumber,
    });
  }

  const addRemarkInput = toMarineRemark(ermInfo, travellerTatoooNumber);
  pnr.addRemark(addRemarkInput);
  return await firstValueFrom(PNRService.sendAddElements(pnr));
};
