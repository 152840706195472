import { PNR } from "websdk-businessobjects";
import { Box } from "@mui/material";
import Collapsible from "../requirements/Collapsible";
import MarineRequirementsForm from "./MarineRequirementsForm";
import { Traveller } from "../../traveller";

interface MarineProps {
  travellers: Traveller[];
  fetchData: () => Promise<void>;
  pnr: PNR;
}

const MarineRequirements = ({ travellers, fetchData, pnr }: MarineProps) => {
  return (
    <Box display="flex" flexDirection="column" alignContent="start">
      <Collapsible title="Marine Requirements" isDefaultExpanded={false}>
        {pnr &&
          travellers.map((traveller) => (
            <MarineRequirementsForm
              key={traveller.tatooNumber}
              traveller={traveller}
              fetchData={fetchData}
              pnr={pnr}
            />
          ))}
      </Collapsible>
    </Box>
  );
};

export default MarineRequirements;
