import "./App.css";
import Requirements from "./features/requirements/Requirements";
import Pricing from "./features/Pricing/Pricing";
import { Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { PNR, PNRService, TST, TSTService } from "websdk-businessobjects";
import { initTravellers } from "./features/requirements/utils";
import { firstValueFrom } from "rxjs";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MarineRequirements from "./features/marine/MarineRequirements";
import Collapsible from "./features/requirements/Collapsible";
import { Traveller } from "./traveller";

function App() {
  const [pnr, setPnr] = useState<PNR | null>(null);
  const [tst, setTst] = useState<TST | null>(null);
  const [retrieveError, setRetrieveError] = useState<Error | null>(null);
  const [travellers, setTravellers] = useState<Traveller[]>([]);
  const [pnrMissing, setPnrMissing] = useState<boolean>(false);
  const [tstMissing, setTstMissing] = useState<boolean>(false);

  const fetchData = async () => {
    try {
      const [pnrData, tstData] = await Promise.all([
        firstValueFrom(PNRService.retrieve()),
        firstValueFrom(TSTService.retrieve()),
      ]);

      if (!pnrData) {
        setPnrMissing(true);
      }

      if (!tstData) {
        setTstMissing(true);
      }

      if (pnrData && tstData) {
        setPnr(pnrData);
        setTst(tstData);
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        setRetrieveError(error);
      } else {
        setRetrieveError(new Error("An unknown error occurred"));
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (pnr && tst) {
      initTravellers(pnr, tst).then((travellers) => {
        setTravellers(travellers);
      });
      console.log(pnr);
    }
  }, [pnr, tst]);

  if (retrieveError) {
    return (
      <Typography color="error">
        There was an error retrieving PNR or TST data. <br /> Error message:{" "}
        {retrieveError.message}
      </Typography>
    );
  }

  if (pnrMissing || tstMissing) {
    return (
      <Typography color="error">
        {pnrMissing && "No PNR data available. "}
        {tstMissing && "No TST data available. "}
      </Typography>
    );
  }

  return (
    <Router>
      <Routes>
        <Route
          path="/monitor"
          element={<Typography>The app is running</Typography>}
        />
        <Route
          path="/"
          element={
            <div className="App">
              <Collapsible title="Travellers" isDefaultExpanded={false}>
                <Typography component="pre" align="left">
                  {JSON.stringify(travellers, null, 2)}
                </Typography>
              </Collapsible>
              <Requirements
                travellers={travellers}
                fetchData={fetchData}
                pnr={pnr!}
              />
              <Pricing travellers={travellers} fetchData={fetchData} />
              <MarineRequirements
                travellers={travellers}
                fetchData={fetchData}
                pnr={pnr!}
              ></MarineRequirements>
            </div>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
