import Collapsible from "../requirements/Collapsible";
import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import { allTacsHaveTpcCard, fetchPrices } from "./services";
import FareTable from "./FareTable";
import { FareGroup, Fare } from "./models";
import { Traveller } from "../../traveller";

interface PricingProps {
  travellers: Traveller[];
  fetchData: () => Promise<void>;
}

const Pricing = ({ travellers, fetchData }: PricingProps) => {
  const [fareGroups, setFareGroups] = useState<FareGroup[] | undefined>();

  const findLowestFare = (categorizedFares: Fare[]) => {
    const newLocal = categorizedFares.sort((a, b) => a.amount - b.amount) ?? [];
    return newLocal.length > 0 ? newLocal[0] : undefined;
  };

  //todo: Return a message if LAC cannot have agreement prices

  return (
    <Collapsible title="Pricing" isDefaultExpanded={false}>
      <Button>Check savings good concience</Button>
      <Button
        onClick={() => {
          fetchPrices(travellers).then((fareGroups) => {
            console.log(fareGroups);
            setFareGroups(fareGroups);
          });
          allTacsHaveTpcCard(travellers).then((result) => {
            console.log(result);
          });
        }}
      >
        Fetch prices
      </Button>

      {fareGroups?.map((f) => (
        <Box key={f.originDestination}>
          {(f.eticket && f.eticket.length > 0 && (
            <Collapsible
              key={f.originDestination}
              title={
                "ETicket: " +
                findLowestFare(f.eticket)?.amount +
                findLowestFare(f.eticket)?.currency +
                ", " +
                f.originDestination
              }
              isDefaultExpanded={false}
            >
              <FareTable fares={f.eticket} />
            </Collapsible>
          )) || (
            <Collapsible
              title="No ETicket fares found"
              isDefaultExpanded={false}
            >
              <Typography>
                No ETicket fares found for {f.originDestination}
              </Typography>
            </Collapsible>
          )}
          <br />
        </Box>
      ))}

      {fareGroups?.map((f) => (
        <Box key={f.originDestination}>
          {(f.tpc && f.tpc.length > 0 && (
            <Collapsible
              key={f.originDestination}
              title={
                "TPC: " +
                findLowestFare(f.tpc)?.amount +
                findLowestFare(f.tpc)?.currency +
                ", " +
                f.originDestination
              }
              isDefaultExpanded={false}
            >
              <FareTable fares={f.tpc} />
            </Collapsible>
          )) || (
            <Collapsible title="No TPC fares found" isDefaultExpanded={false}>
              <Typography>
                No TPC fares found for {f.originDestination}
              </Typography>
            </Collapsible>
          )}
        </Box>
      ))}
    </Collapsible>
  );
};

export default Pricing;
