import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface CollapsibleTypographyProps {
  title: string;
  isDefaultExpanded: boolean;
  children: React.ReactNode;
}

const Collapsible: React.FC<CollapsibleTypographyProps> = ({
  title,
  isDefaultExpanded,
  children,
}) => {
  return (
    <Accordion
      variant="outlined"
      defaultExpanded={isDefaultExpanded}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default Collapsible;
