import {
  PNR,
  PNRService,
} from "websdk-businessobjects";
import { existingRequirementRemarks, requirementRemarks } from "./utils";
import { firstValueFrom } from "rxjs";
import { GetLacResponse, StatusCode } from "../../lacDto";
import {
  Traveller,
  RequirementDetails,
} from "../../traveller";
import lacTestData from "../../dummyData/lac.json";

export const updateRequirements = async (pnr: PNR, traveller: Traveller) => {
  const existingRemarks = existingRequirementRemarks(
    pnr,
    traveller.tatooNumber
  );
  if (existingRemarks.length > 0) {
    pnr.addCancel(existingRemarks);
    await firstValueFrom(PNRService.sendCancelElements(pnr));
  }

  pnr.addRemark([...requirementRemarks(traveller)]);
  await firstValueFrom(PNRService.sendAddElements(pnr));
};

export const fetchLac = async (lacNo: number) => {
  if (process.env.REACT_APP_ENV === "local")
    return Promise.resolve(lacTestData);

  const response = await fetch(
    `${process.env.REACT_APP_M3_URL}lac?LacNo=${lacNo}`
  );
  return await (response.json() as Promise<GetLacResponse>);
};

export const validateRequirement = async (
  requirementDetails: RequirementDetails,
  lacNo: number
): Promise<RequirementDetails> => {
  return fetch(
    `${process.env.REACT_APP_M3_URL}validatectrl-${requirementDetails.name}?LacNo=${lacNo}&Param=${requirementDetails.value}`
  )
    .then((res) => res.json())
    .then((res) => {
      return {
        ...requirementDetails,
        isValid: res.Status.Code === StatusCode.Success,
        errorMessage: res.Status.Message,
      };
    });
};

export const validateRequirements = async (
  lacNo: number,
  requirements: RequirementDetails[]
): Promise<RequirementDetails[]> => {
  const validationTasks = requirements.map((req) =>
    validateRequirement(req, lacNo)
  );
  return await Promise.all(validationTasks);
};



