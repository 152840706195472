import { PNR } from "websdk-businessobjects";
import { Cnf } from "../../traveller";
import { Fare_PricePNRWithLowerFares } from "./dtos/pricePNRWithLowerFaresRequest";
import { PricePNRResponse, FareList } from "./dtos/pricePNRWithLowerFaresResponse";
import { Fare_PriceUpsellPNR } from "./dtos/priceUpsellPNRRequest";
import { Ticket_CreateTSTFromPricing } from "./dtos/ticketCreateTSTFromPricingRequest";
import { Fare, FareGroup, LuggageDetails } from "./models";

export const toCreateFareRequest = (
  fare: Fare
): Ticket_CreateTSTFromPricing => {
  return {
    psaList: [
      {
        itemReference: {
          referenceType: fare.reference.referenceType,
          uniqueReference: fare.reference.uniqueReference,
        },
      },
    ],
  };
};

export const extractTacNumber = (pnr: PNR, nameElementTatooNumber: number) => {
  const tacNoKey = "M3+T+";
  const tacNo = pnr.rmElements
    .find(
      (rm) =>
        rm.freeFlowText.startsWith(tacNoKey) &&
        rm.associations.some(
          (a) =>
            a.tatooNumber === nameElementTatooNumber.toString() &&
            a.segmentType === "PT"
        )
    )
    ?.freeFlowText.slice(tacNoKey.length);
  if (!tacNo) return null;
  return parseInt(tacNo);
};
export function toFareGroups(
  priceLowerFare: PricePNRResponse
): FareGroup[] | undefined {
  const fareGroupsMap: { [originDestination: string]: FareGroup } = {};

  priceLowerFare.response.model.fareList.forEach((f) => {
    const originDestination = f.originDestination.cityCode.join(" - ");

    if (!fareGroupsMap[originDestination]) {
      fareGroupsMap[originDestination] = {
        tpc: [],
        eticket: [],
        originDestination: originDestination,
      };
    }

    const fare: Fare = toFare(f);

    if (
      f.fareComponentDetailsGroup[0].componentClassInfo?.fareBasisDetails?.rateTariffClass
        ?.toLowerCase()
        ?.includes("tpc")
    ) {
      fareGroupsMap[originDestination].tpc.push(fare);
    } else {
      fareGroupsMap[originDestination].eticket.push(fare);
    }
  });

  const fareGroups = Object.values(fareGroupsMap);
  return fareGroups.length > 0 ? fareGroups : undefined;
}
function toFare(f: FareList): Fare {
  const luggageDetails = toLuggageDetails(f);

  return {
    fareFamily: f.fareComponentDetailsGroup
      ? f.fareComponentDetailsGroup[0].fareFamilyDetails?.fareFamilyname?.toLowerCase()
      : "",
    fareBasis: f.fareComponentDetailsGroup
      ? f.fareComponentDetailsGroup[0].componentClassInfo?.fareBasisDetails
          ?.rateTariffClass
      : "",
    currency: f.fareDataInformation?.fareDataSupInformation?.find(
      (fd) => fd.fareDataQualifier === "712" //712 is the total fare including taxes
    )?.fareCurrency!,
    amount: parseFloat(
      f.fareDataInformation.fareDataSupInformation?.find(
        (fd) => fd.fareDataQualifier === "712"
      )!.fareAmount
    ),
    luggage: luggageDetails,
    reference: {
      uniqueReference: f.fareReference.uniqueReference,
      referenceType: f.fareReference.referenceType,
    },
  };
}

function toLuggageDetails(f: FareList): LuggageDetails[] {
  return f.segmentInformation
    .filter((s) => s.bagAllowanceInformation.bagAllowanceDetails)
    .map((s) => {
      const baggageDetails = s?.bagAllowanceInformation?.bagAllowanceDetails;
      const newLocal: LuggageDetails = {
        baggageType: baggageDetails.baggageType,
        baggageQuantity: baggageDetails.baggageQuantity,
        baggageWeight: baggageDetails.baggageWeight,
        measureUnit: baggageDetails.measureUnit,
      };
      return newLocal;
    });
}

export const requestServiceNames = {
  pricePNRWithLowerFare: "ws.pricePNRWithLowerFares_v23.2",
  priceUpsellPNR: "ws.priceUpsellPNR_v23.2",
  createTSTfromPricing: "ws.createTSTFromPricing_04.1",
  rebookAirSegment: "ws.rebookAirSegment_v20.1",
};

export const pricingOptionKeys = {
  RW: "RW",
  RLI: "RLI",
  RP: "RP",
  RLA: "RLA",
};

export const toPricePnrWithLowerFareRequest = (
  cnfs: Cnf[]
): Fare_PricePNRWithLowerFares => {
  return toPricingRequest(cnfs);
};

export const toUpsellPnrRequest = (cnfs: Cnf[]): Fare_PriceUpsellPNR => {
  return toPricingRequest(cnfs);
};

const toPricingRequest = (cnfs: Cnf[]): Fare_PriceUpsellPNR => {
  if (cnfs.length === 0) {
    return {
      pricingOptionGroup: [
        {
          pricingOptionKey: {
            pricingOptionKey: pricingOptionKeys.RLA,
          },
        },
      ],
    };
  }
  return {
    pricingOptionGroup: [
      {
        pricingOptionKey: {
          pricingOptionKey: pricingOptionKeys.RW,
        },
        optionDetail: {
          criteriaDetails: cnfs.map((cnf) => ({
            attributeType: cnf.CnfCode,
          })),
        },
      },
      {
        pricingOptionKey: {
          pricingOptionKey: pricingOptionKeys.RLI,
        },
      },
      {
        pricingOptionKey: {
          pricingOptionKey: pricingOptionKeys.RP,
        },
      },
    ],
  };
};