import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { useEffect, useState } from "react";
import { PNR } from "websdk-businessobjects";

import { Traveller, MarineCode } from "../../traveller";
import { createOrUpdateMarineRm } from "./services";

interface FlightRequirementsFormProps {
  traveller: Traveller;
  pnr: PNR;
  fetchData: () => Promise<void>;
}

const EditErmInfo: React.FC<FlightRequirementsFormProps> = ({
  traveller,
  fetchData,
  pnr,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [saveMessage, setSaveMessage] = useState<string | null>(null);
  const [ermName, setErmName] = useState<string>(
    traveller.energyMarineInfo?.ermName ?? ""
  );
  const [regCountryCode, setRegCountryCode] = useState<string>(
    traveller.energyMarineInfo?.regCountryCode ?? ""
  );
  const [marineCode, setMarineCode] = useState<MarineCode | null>(
    traveller.energyMarineInfo?.marineCode ?? null
  );

  useEffect(() => {
    setErmName(traveller.energyMarineInfo?.ermName ?? "");
    setRegCountryCode(traveller.energyMarineInfo?.regCountryCode ?? "");
    setMarineCode(traveller.energyMarineInfo?.marineCode ?? null);
  }, [traveller]);

  const toMarineCode = (marineCode: string): MarineCode => {
    switch (marineCode) {
      case "VSL":
        return MarineCode.VSL;
      case "RIG":
        return MarineCode.RIG;
      case "WMF":
        return MarineCode.WMF;
      default:
        throw new Error("Invalid marine code");
    }
  };

  const save = async (): Promise<void> => {
    setIsLoading(true);
    setSaveMessage(null);
    try {
      await createOrUpdateMarineRm(pnr, traveller.tatooNumber, {
        ermName,
        regCountryCode,
        marineCode: marineCode!,
      });
      await fetchData();
      setSaveMessage("Changes saved successfully.");
    } catch (error) {
      console.error("Failed to save marine requirements:", error);
      setSaveMessage("Failed to save changes.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box display="flex" flexDirection="column" mb={1.5}>
      <Typography>{traveller.fullName}</Typography>
      <Stack spacing={2}>
        <TextField
          label="ERM Name"
          value={ermName}
          onChange={(e) => setErmName(e.target.value)}
        />
        <TextField
          label="Reg Country Code"
          value={regCountryCode}
          onChange={(e) => setRegCountryCode(e.target.value)}
        />
        <FormControl>
          <InputLabel>Marine Code</InputLabel>
          <Select
            value={marineCode ?? ""}
            onChange={(e) => setMarineCode(toMarineCode(e.target.value))}
          >
            <MenuItem value={MarineCode.VSL}>VSL</MenuItem>
            <MenuItem value={MarineCode.RIG}>RIG</MenuItem>
            <MenuItem value={MarineCode.WMF}>WMF</MenuItem>
          </Select>
        </FormControl>
        <LoadingButton loading={isLoading} variant="contained" onClick={save}>
          Save
        </LoadingButton>
        {saveMessage && <Typography>{saveMessage}</Typography>}
      </Stack>
    </Box>
  );
};

export default EditErmInfo;
